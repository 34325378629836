body {
  margin: 0;
  font-family: "Ubuntu", sans-serif !important;
  font-weight: 400;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 800;
}

a {
  transition: ease-in-out 300ms;
}

.App {
  overflow: hidden;
}

.navbar {
  transition: ease-in-out 200ms;
  z-index: 999999;
}

.navbar.filled {
  background-color: rgba(255, 255, 255, 0.97);
}

.block {
  padding-top: 4em;
  padding-bottom: 4em;
  z-index: 999;
}

.skew {
  position: relative;
}

.skew::before {
  content: "";
  display: block;
  width: 100%;
  height: 35%;
  position: absolute;
  top: -1%;
  background: #f8f9fa;
  transform-origin: top left;
  transform: skew(0deg, -6deg);
  z-index: -1;
}

.hero {
  z-index: 1900;
}

.hero-text {
  align-self: center;
  z-index: 2000;
}

.hero-text .guru {
  font-size: 3.9em;
}

.hero-text .guru span {
  font-weight: 400 !important;
  font-size: 0.8em;
}

.hero-text .description {
  font-size: 1.1em;
}

.hero-bg {
  position: absolute;
  top: -10em;
  right: -10em;
  height: 110%;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: top right;
  z-index: 1900;
}

.hero-phone {
  animation: float 5.5s infinite ease-in-out;
  z-index: 2000;
}

@keyframes float {
  0% {
    transform: translateY(0) /*rotateZ(45deg)*/;
    -webkit-transform: translateY(0) /*rotateZ(45deg)*/;
  }

  50% {
    transform: translateY(1rem) /*rotateZ(45deg)*/;
    -webkit-transform: translateY(1rem) /*rotateZ(45deg)*/;
  }

  100% {
    transform: translateY(0) /*rotateZ(45deg)*/;
    -webkit-transform: translateY(0) /*rotateZ(45deg)*/;
  }
}

@media (max-width: 576px) {
  body {
    font-size: 14px;
  }
  .hero {
    align-items: flex-end !important;
    justify-content: center !important;
  }

  .hero-bg {
    top: -9em;
    right: -9em;
    width: 120% !important;
  }

  .hero-text {
    margin-bottom: 7em;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hero {
    align-items: flex-end !important;
  }

  .hero-bg {
    top: -7em;
    right: -7em;
    width: 100% !important;
  }

  .hero-text {
    margin-bottom: 6em;
  }
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}

.app-buttons {

}
.app-buttons.small i {
  font-size: 1.2em;
}

.app-buttons .badge {
  display: inline-block;
  position: relative;
  width: 135px;
  height: 40px;
  overflow: hidden;
}

.app-buttons .badge + .badge {
  margin-left: 10px;
}

.app-buttons .badge.apple img {
  width: 135px;
  height: 40px;
  position: absolute;
  top: 0px;
  left: 0px;
}

.app-buttons .badge.google img {
  width: 155px;
  height: 60px;
  position: absolute;
  top: -10px;
  left: -10px;
}

.card {
  background-color: transparent !important;
  border: none;
}

.card .card-text {
  color: #666666;
}

.card .card-title {
  position: relative;
}

.card .card-title::after {
  content: "";
  display: block;
  border-bottom: 3px solid #ffac41;
  position: absolute;
  width: 13%;
  height: 2px;
  bottom: -12px;
}

.page {
  margin-top: 7em;
  margin-bottom: 6em;
}

.page.privacy ol {
  counter-reset: item;
  list-style: none;
  padding-left: 0;
}
.page.privacy ul {
  list-style: square;
  margin-bottom: 1.7em;
}

.page.privacy ol > li {
  display: block;
}

.page.privacy ol > li:before {
  content: counters(item, ".") ". ";
  counter-increment: item;
  font-size: 1.3em;
  font-weight: 600;
}

.page.privacy h3 {
  display: inline-block !important;
  font-size: 1.3em;
  font-weight: 600;
}

.footer {
  padding-top: 3em;
  padding-bottom: 3em;
}

.slogan {
  font-size: 0.9em;
}

.footer-links a {
  text-decoration: none;
  color: #efefef;
}

.footer-links.inner a {
  font-weight: bold;
  font-size: 0.85em;
}

.footer-links.inner a:hover {
  color: #ff1e56 !important;
}

.footer-links.inner li:not(.footer-links.inner li:first-child) {
  position: relative;
  margin-left: 14px;
}

.footer-links.inner li:not(.footer-links.inner li:first-child)::before {
  content: "·";
  position: absolute;
  left: -9px;
  top: 0;
}
.footer-links.social a {
  font-size: 1.9em;
}
